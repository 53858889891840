
//import Rails from "@rails/ujs"
require("./application");

import "../stylesheets/frontend.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'


/* FRONT ASSETS */

import "../vendor/backend/bracket/lib/cookieconsent/dist/cookieconsent";


import AOS from "../vendor/frontend/Append/assets/vendor/aos/aos.js";
window.AOS = AOS;
//import AOS from 'aos';
//require("../vendor/front/assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
import "../vendor/frontend/Append/assets/vendor/glightbox/js/glightbox";
require("../vendor/frontend/assets/vendor/isotope-layout/isotope.pkgd.min.js");
import Swiper from  "../vendor/frontend/assets/vendor/swiper/swiper-bundle.min.js";
window.Swiper = Swiper;

//import Odometer from "./odometer";
//window.Odometer = Odometer;

import toastr from  "./toastr";
window.toastr = toastr;

//require("../vendor/frontend/Append/assets/js/main.js");
import "../vendor/frontend/Append/assets/js/main.js";


require("./social-share-button");


require("./cookieconsent_init");

require("./custom");
//require("./maps");
require("./hide_header");
//require("./init_odometer");




/* END */
