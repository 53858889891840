/*$(document).on('turbolinks:load', function() {  

  $(this).find('.customSelect select').each(function() {
    var dropdownParent = $(document.body);
    console.log(dropdownParent);
      $(this).select2({
        dropdownParent: dropdownParent,
        width: 'resolve' ,
      });
  });

});*/

window.addEventListener('turbolinks:load', () => {
  $('.select2').select2({
    width: 'resolve' ,
  });
})