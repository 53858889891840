/**
* Template Name: Flexor
* Updated: Jul 27 2023 with Bootstrap v5.3.1
* Template URL: https://bootstrapmade.com/flexor-free-multipurpose-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
$(document).on('turbolinks:load',function() {
  "use strict";

  

 
  /**
   * Clients Slider
   */
  new Swiper('.clients-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      992: {
        slidesPerView: 5,
        spaceBetween: 20
      }
    }
  });

  /**
   * Testimonials slider
   */
  /*new Swiper('.testimonials-slider', {
    speed: 600,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    slidesPerView: 'auto',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });
  */

  /**
   * Jobs slider
   */
  /*new Swiper('.latest-jobs-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 3,
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40
        },
        480: {
          slidesPerView: 1.25,
          spaceBetween: 60
        },
        640: {
          slidesPerView: 1.25,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      }
  });
  */

  /**
   * Testimonials slider
   */
  new Swiper('.latest-jobs-slider', {
      slidesPerView: 3,
      spaceBetween: 20,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      },
      on: {
        init: function() {
          checkArrow('.latest-jobs-slider');
        },
        resize: function () {
          checkArrow('.latest-jobs-slider');
        }
      }
  });

  /**
   * Testimonials slider
   */
  new Swiper('.our-selection-trainings-slider', {
      speed: 400,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 2.25,
      spaceBetween: 20,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 2.25,
          spaceBetween: 20
        }
      },
      on: {
        init: function() {
          checkArrow('.our-selection-trainings-slider');
        },
        resize: function () {
          checkArrow('.our-selection-trainings-slider');
        }
      }
  });


  /**
   * Testimonials slider
   */
  new Swiper('.testimonials-slider', {
      slidesPerView: 2,
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 40
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 60
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 20
        }
      }
  });


  /**
   * Testimonials slider
   */
  new Swiper('.latest-candidates-slider', {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
      // Responsive breakpoints
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        640: {
          slidesPerView: 1.25,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      }
  });

  

  /**
   * Posts slider
   */
  new Swiper('.posts-slider', {
      slidesPerView: 1,
      spaceBetween: 30,
      freeMode: true,
      pagination: {
        el: ".swiper-pagination",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        type: 'bullets',
        clickable: true,
      },
  });


  /**
   * Porfolio isotope and filter
   */
  /*window.addEventListener('load', () => {
    let portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      let portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      let portfolioFilters = select('#portfolio-flters li', true);

      on('click', '#portfolio-flters li', function(e) {
        e.preventDefault();
        portfolioFilters.forEach(function(el) {
          el.classList.remove('filter-active');
        });
        this.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: this.getAttribute('data-filter')
        });
        portfolioIsotope.on('arrangeComplete', function() {
          AOS.refresh()
        });
      }, true);
    }

  });
  */

  /**
   * Initiate portfolio lightbox 
   */
  /*const portfolioLightbox = GLightbox({
    selector: '.portfolio-lightbox'
  });
  */

  /**
   * Portfolio details slider
   */
  new Swiper('.portfolio-details-slider', {
    speed: 400,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    }
  });

  /**
   * Animation on scroll
   */
  window.addEventListener('load', () => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    })
  });



  function checkArrow(section) {
 
    
    var swiperPrev = document.querySelector(`${section} .swiper-button-prev`);
    var swiperNext = document.querySelector(`${section} .swiper-button-next`);
    
    if ( window.innerWidth < 1024  ) {
      //console.log('Success', window.innerWidth);
      //swiperPrev.style.display = 'block';
      //swiperNext.style.display = 'block';
      swiperPrev.style.display = 'none';
      swiperNext.style.display = 'none';
    } else {
      //swiperPrev.style.display = 'none';
      //swiperNext.style.display = 'none';
      swiperPrev.style.display = 'block';
      swiperNext.style.display = 'block';
    }
  }

});//()